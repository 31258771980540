header {
    height: fit-content;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    position: relative;
    min-height: max-content;
    
}

/* ====================== CTA ========================= */
.cta {
   margin-top: 2.5rem; 
   display: flex;
   gap: 1.2rem;
   justify-content: center;
}

/* ======================= Socials ======================= */
.header__socials {
    display:  flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* =-================= Me ========================= */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    aspect-ratio: auto;
    min-height: 30%;

    margin-left: auto;
    margin-right: auto;
    
    margin-top: 6rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
    display: flex;
   justify-content: center;
}

/* =-================= Scroll Down ========================= */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* =========================== Media Queries (Medium Devices) =========================== */
@media screen and (max-width: 1024px){
    header {
        height: 100%;
    }
}

/* =========================== Media Queries (Small Devices) =========================== */
@media screen and (max-width: 600px){
    header {
        height: 100%
    }

    .header__socials,
    .scroll__down {
        display: none;
    }
}