.container.testimonials__container {
    width: 60%;
    padding-bottom: 4%;
    height: 10%;
    max-width: fit-content;
    margin: 10rem auto;
    
}

.client__avatar {
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-variant);
}

.testimonial {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
    
}
.client__photo {
    width: auto;
    height: auto;
    max-width: 86%;
    margin-left: auto;
    margin-right: auto;
    max-height: 86%;

}
.client__review {
    color: var(--color-light);
    font-weight: 300;
    text-align: center;
    width: 80%;
    padding-top: 0.8rem ;
}



.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
     position: relative;
     margin: 2rem;
}

.swiper-navigation-clickable {
    background: var(--color-primary-variant);
}

.swiper-button-next,
.swiper-button-prev {
    position: relative;
}

/* =========================== Media Queries (Medium Devices) =========================== */
@media screen and (max-width: 1024px) {
    .container.testimonials__container {
        width: 86%;
        height: 62rem;
        vertical-align: center;
    }
}

/* =========================== Media Queries (Small Devices) =========================== */
@media screen and (max-width: 600px) {
    .container.testimonials__container {
        width: var(--container-width-sm);
        height: 63rem;
    }

    .client__review {
        width: var(--container-width-sm)
    }
   
}

@media screen and (max-width: 400px) {
    .container.testimonials__container {
        width: var(--container-width-sm);
        height: 72rem;
    }

    .client__review {
        width: var(--container-width-sm)
    }
   
}

@media screen and (max-width: 300px) {
    .container.testimonials__container {
        width: var(--container-width-sm);
        height: 94rem;
    }

    .client__review {
        width: var(--container-width-sm)
    }
   
}