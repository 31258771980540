.container {
    text-align: center;
}

.container.college__container {
    width: 40%;
    padding-bottom: 4rem;
    margin-top: 3rem;
    border: 1px solid transparent;
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}


.college__relevant-coursework li{
    gap: 1rem;
    margin-bottom: 0.8rem;
    text-align: center;
    text-align: left;
}
.college__relevant-coursework h3{
    gap: 1rem;
    margin-top: 0.8rem;
    margin-bottom: -1.2rem;
    
}

@media screen and (max-width: 1024px) {
    .container.college__container {
        width: 60%;
    }
}

.cta.btn.btn-primary {
    color: var(--color-white);
    margin-top: -3rem;
}

/* =========================== Media Queries (Small Devices) =========================== */
@media screen and (max-width: 600px) {
    .container.college__container {
        width: var(--container-width-sm);
    }

    .client__review {
        width: var(--container-width-sm)
    }
   
}